import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInfinitySlider,
  COverflowTable,
  CSimpleCard,
  CTable,
  CSectTitle03,
  CFlowList,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  LBanquetContact,
  CFaqList,
  CMedia,
  CFocalMedia,
  CFlexibleMedia,
  AssetImage,
} from '../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        type="ja"
        data={{
          title: {
            main: (
              <>
                CO₂ゼロ MICE®の
                <br className="u_sp" />
                ご案内
              </>
            ),
            sub: <></>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/co2zero_mice/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/co2zero_mice/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="l_sect04 u_bgGray">
        <LWrap>
          <p className="c_sectLead">
            ロイヤルパークホテルで開催するイベント・会議で使用する電気を再生可能エネルギーに置き換えて、CO₂排出を実質ゼロで開催いただけます。
            <br />
            温暖化対策やSDGsへの取組みに、ぜひご利用ください。
          </p>
        </LWrap>
      </div>
      <section className="l_sect02 u_pt50">
        <LWrap>
          <CSectTitle03 exClass="u_mb60" title="CO₂ゼロ MICE®とは" />
          <figure className="u_mb40">
            <AssetImage
              src={'/assets/images/banquet/co2zero_mice/img_co2zero_mice.png'}
              alt=""
              loading="lazy"
            />
          </figure>
          <p className="u_mb10">
            MICEを実施する際に、その会場で使用される電気を再生可能エネルギーに置き換えることで、CO₂を実質0にできるサービスです。再生可能エネルギーの調達、実際に使用されたエネルギーが再生可能エネルギーの環境価値*としてどれほどなのかを算出し、事後検証などを含めてパッケージにした商品です。
            <br />
            （企画:株式会社JTBコミュニケーションデザイン）
          </p>
          <ul className="c_noteList u_mb60">
            <li>
              環境価値とは
              <br />
              環境負荷の低減、化石燃料の節減、CO₂排出削減など、再生可能エネルギーの利用によってもたらされる電気エネルギー以外の付加価値のこと
            </li>
          </ul>

          <CSectTitle03 exClass="u_mb60" title="本オプションのメリット" />
          <div className="u_mb60" style={{ maxWidth: '960px' }}>
            <CFlexibleMedia
              reverse={true}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/co2zero_mice/img_co2zero_mice02.png',
                    alt: '',
                  },
                  imgWidth: '354px',
                  content: (
                    <>
                      <ul className="c_circleList u_mb20">
                        <li>｢グリーン電力証書*｣の発行</li>
                        <li>｢CO₂ゼロ｣イベントとして告知が可能</li>
                        <li>SDGsやISO14001に取組む企業としての評価向上</li>
                        <li>東京都総量削減義務や温対法などの削減義務に貢献</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>
                          グリーン電力証書とは
                          <br />
                          一般財団法人日本品質保証機構が認証している制度で、自前の発電設備を持たない場合でも、証書を購入することで再生可能エネルギー普及に貢献したことが証明できます。社内外へ取組み活動報告として告知にもご利用いただけます。
                        </li>
                      </ul>
                    </>
                  ),
                },
              ]}
            />
          </div>
          <CSectTitle03 exClass="u_mb60" title="ご料金について" />
          <div className="c_co2zeromicePrice">
            <div className="left">ご宴席料金</div>
            <div className="unit">+</div>
            <div className="right">
              CO<sub>₂</sub>ゼロ MICE®ご利用料金
            </div>
          </div>
          <CBtnList
            exClass="u_mt40"
            data={[
              {
                label: '料金一覧はこちら(PDF)',
                link: {
                  href: '/assets/files/pdf/co2zero_mice.pdf',
                  blank: true,
                },
                color: 'bgWhite03',
                icon: 'document',
              },
            ]}
          />
          <div className="u_tac u_mt20 u_mb60">
            <ul className="c_noteList">
              <li>料金にはご宴席料金は含まれません。</li>
            </ul>
          </div>
          <CSectTitle03 exClass="u_mb60" title="よくあるご質問" />
          <CFaqList
            data={[
              {
                q: 'いつまでに申込が必要ですか?',
                a: (
                  <>
                    <p>
                      お申込は、イベント開催の45日前までとなります。（今後短縮を予定しております）
                    </p>
                    <ul className="c_noteList">
                      <li>45日前以降の変更・取消はできません。</li>
                    </ul>
                  </>
                ),
              },
              {
                q: 'オンラインでの会議/イベントでも利用可能でしょうか?',
                a: (
                  <>
                    オンライン会議／イベントでの利用も可能です。ただし、オンラインの場合は、会場を利用する拠点のみとなります。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
